import React, { useState, useEffect } from "react";
import { Row, Button, Input, message, Divider, Layout, Spin } from "antd";
import api from "./../../Api";

const { Content } = Layout;

const SpecsDataView = () => {
  const [specs, setSpecs] = useState([]);
  const [deleted, setDeleted] = useState([]);
  const [spinning, setSpinning] = useState(true);

  useEffect(() => {
    const getSpecs = async () => {
      const { data } = await api.specs().get();
      setSpecs(data.sort((x, y) => (x.name > y.name ? 1 : -1)));
      setSpinning(false);
    };
    getSpecs();
  }, []);

  const handleRowsChange = (event) => {
    const _tempRows = [...specs];
    _tempRows[event.target.dataset.id][event.target.name] = event.target.value;
    setSpecs(_tempRows);
  };

  const addNewRow = () => {
    setSpecs((prevRows) => [...prevRows, { name: "", translate: "" }]);
  };

  const handleRemoveSpecificRow = (index, id) => async () => {
    await api
      .products()
      .get()
      .then((response) => {
        const data = response.data.map((x) => x.features);
        data.forEach((element) => {
          if (element) {
            if (element.filter((x) => x.selected === id).length === 0) {
              specs.splice(index, 1);
              setDeleted([...deleted, id]);
              setSpecs((specs) => [...specs]);
            } else {
              alert(
                "Number " + (index + 1) + " row have attached with product"
              );
            }
          }
        });
      });
  };
  const handleSubmit = async (event) => {
    event.preventDefault();

    //delete existing list
    deleted.forEach(async (x) => {
      await api.specs(x).delete();
    });
    //post specs data
    specs.forEach(async (spec) => {
      if (spec.id === undefined) {
        await api.specs().post({ name: spec.name ?? "", translate: spec.translate ?? "" });
      }
    });
    await message.success("Specifications successfully edited", 1);
    window.location.reload();
  };

  return (
    <div>
      <Spin spinning={spinning} delay={300}>
        <Layout>
          <Content style={{ padding: "32px 50px" }}>
            <h1>Specifications</h1>
            <Row type="flex" justify="center" align="middle">
              <table>
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Name(English):</th>
                    <th>Name(Armenian):</th>
                  </tr>
                </thead>
                <tbody>
                  {specs.map((spec, index) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>
                        <Input
                          name="name"
                          data-id={index}
                          type="text"
                          value={spec.name}
                          onChange={handleRowsChange}
                        />
                      </td>
                      <td>
                        <Input
                          name="translate"
                          data-id={index}
                          type="text"
                          value={spec.translate}
                          onChange={handleRowsChange}
                        />
                      </td>
                      <td>
                        <Button
                          type="danger"
                          shape="circle"
                          onClick={handleRemoveSpecificRow(index, spec.id)}
                        >
                          -
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </Row>
            <Row type="flex" justify="center" align="middle">
              <Button shape="circle" onClick={addNewRow}>
                +
              </Button>
            </Row>
            <Divider />
            <Row type="flex" justify="center" align="middle">
              <Button onClick={handleSubmit}>Update</Button>
            </Row>
          </Content>
        </Layout>
      </Spin>
    </div>
  );
};

export default SpecsDataView;
