import React from "react";
import { Button } from "antd";
import ReactExport from "react-data-export";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

function ExcelProducts({
  products,
  prices,
  features,
  categories,
  manufacturers,
  specs,
}) {
  return (
    <div>
      <ExcelFile
        element={
          <Button
            className="download"
            type="danger"
            icon="download"
            size="large"
          >
            Download Data
          </Button>
        }
      >
        <ExcelSheet data={products} name="Products">
          <ExcelColumn label="ID" value="id" />
          <ExcelColumn label="Code" value="code" />
          <ExcelColumn label="Name" value="name" />
          <ExcelColumn label="Description" value="description" />
          <ExcelColumn label="Category Id" value="categoryId" />
          <ExcelColumn label="Manufacturer Id" value="manufacturerId" />
          <ExcelColumn label="Is Active" value="isActive" />
          <ExcelColumn label="Favorite" value="favorite" />
          <ExcelColumn label="In Stock" value="inStock" />
          <ExcelColumn label="By Order" value="byOrder" />
        </ExcelSheet>
        <ExcelSheet data={prices} name="Prices">
          <ExcelColumn label="Product Id" value="product" />
          <ExcelColumn label="Date" value="priceDate" />
          <ExcelColumn label="Price" value="priceSum" />
        </ExcelSheet>
        <ExcelSheet data={features} name="Description">
          <ExcelColumn label="Product Id" value="product" />
          <ExcelColumn label="Selected" value="selected" />
          <ExcelColumn label="Description" value="description" />
        </ExcelSheet>
        <ExcelSheet data={categories} name="Categories">
          <ExcelColumn label="Category Id" value="id" />
          <ExcelColumn label="Code" value="code" />
          <ExcelColumn label="Name" value="name" />
          <ExcelColumn label="Description" value="description" />
          <ExcelColumn label="Is Active" value="isActive" />
          <ExcelColumn label="Parent Id" value="parentId" />
        </ExcelSheet>
        <ExcelSheet data={manufacturers} name="Manufacturers">
          <ExcelColumn label="Manufacturer Id" value="id" />
          <ExcelColumn label="Code" value="code" />
          <ExcelColumn label="Name" value="name" />
          <ExcelColumn label="Description" value="description" />
          <ExcelColumn label="Is Active" value="isActive" />
        </ExcelSheet>
        <ExcelSheet data={specs} name="Specs">
          <ExcelColumn label="Spec Id" value="id" />
          <ExcelColumn label="Name" value="name" />
          <ExcelColumn label="Translate" value="translate" />
        </ExcelSheet>
      </ExcelFile>
    </div>
  );
}

export default ExcelProducts;
