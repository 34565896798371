import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Row, Input, Button, Form, Select, Layout, Col, message } from "antd";
import api from "./../../Api";
import { ProductNameExists } from "../Utils/Validator";

const { Option } = Select;
const { Content } = Layout;

//Get Categories and Manufacturers
const useCategories = () => {
  const [categories, setCategories] = useState([]);
  const [manufacturers, setManufacturers] = useState([]);

  useEffect(() => {
    const getCategories = async () => {
      const res = await api.categories().get();
      setCategories(res.data.filter((x) => x.parentId !== 0));
    };
    const getManufacturers = async () => {
      const { data } = await api.manufacturers().get();
      setManufacturers(data);
    };
    getCategories();
    getManufacturers();
  }, []);
  return {
    categories,
    manufacturers,
  };
};

const useUpdateForm = (defaultValues) => {
  const [inputs, setInputs] = useState({ ...defaultValues });
  const history = useHistory();

  const handleSubmit = async (event) => {
    event.preventDefault();
    if ((await ProductNameExists(inputs.name, null)) > 0) {
      await message.error(`"${inputs.name}" product name already exists.`, 2);
      return;
    }
    let search = window.location.search;
    let params = new URLSearchParams(search);
    let productID = params.get("productID");
    const data = {
      id: parseInt(productID),
      name: inputs.name ?? "",
      categoryId: inputs.categoryId ?? 0,
      manufacturerId: inputs.manufacturerId ?? 0,
      createdAt: new Date(),
    };
    api
      .products()
      .post(data)
      .then(async (response) => {
        await message.success("This product successfully added", 1);
        history.push(`/product/?productID=${response.data.id}`);
      })
      .catch((error) => console.log(error));
  };

  const handleInputChange = (event) => {
    event.persist();
    setInputs((inputs) => ({
      ...inputs,
      [event.target.name]: event.target.value,
    }));
  };

  const handleCategoryChange = (value) => {
    setInputs((inputs) => ({
      ...inputs,
      categoryId: value,
    }));
  };
  const handleManufacturerChange = (value) => {
    setInputs((inputs) => ({
      ...inputs,
      manufacturerId: value,
    }));
  };

  return {
    handleSubmit,
    handleInputChange,
    inputs,
    handleCategoryChange,
    handleManufacturerChange,
  };
};

const NewProductForm = ({ product, categories, manufacturers }) => {
  const {
    inputs,
    handleInputChange,
    handleCategoryChange,
    handleManufacturerChange,
    handleSubmit,
  } = useUpdateForm(product);

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Item label="Product name">
        <Input
          type="text"
          name="name"
          onChange={handleInputChange}
          value={inputs.name}
        />
      </Form.Item>
      <Form.Item label="Category">
        <Select
          showSearch
          name="categoryId"
          style={{ width: "100%" }}
          placeholder="Please select category"
          onChange={handleCategoryChange}
          value={inputs.categoryId}
          filterOption={(input, option) =>
            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >=
            0
          }
        >
          {categories.map((cat) => (
            <Option value={cat.id} key={cat.id}>
              {cat.name}
            </Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item label="Manufacturer">
        <Select
          showSearch
          name="manufacturerId"
          style={{ width: "100%" }}
          placeholder="Please select manufacturer"
          onChange={handleManufacturerChange}
          value={inputs.manufacturerId}
          filterOption={(input, option) =>
            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >=
            0
          }
        >
          {manufacturers.map((manuf) => (
            <Option value={manuf.id} key={manuf.id}>
              {manuf.name}
            </Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item>
        <Button htmlType="submit">Create</Button>
      </Form.Item>
    </Form>
  );
};

const NewProduct = () => {
  const { categories, manufacturers } = useCategories();
  const [product, setProduct] = useState({});

  useEffect(() => {
    const fetchProduct = async () => {
      let search = window.location.search;
      let params = new URLSearchParams(search);
      let productID = params.get("productID");

      const res = await api.products(productID).get()
      setProduct(res.data[0])
    };
    fetchProduct();
  }, []);
  return (
    <div className="product">
      <Layout>
        <Content style={{ padding: "32px 50px" }}>
          <Row>
            <Col span={12}>
              <div>
                <h1>Create new product</h1>
                <NewProductForm
                  product={product}
                  categories={categories}
                  manufacturers={manufacturers}
                />
              </div>
            </Col>
            <Col span={12}></Col>
          </Row>
        </Content>
      </Layout>
    </div>
  );
};
export default NewProduct;
