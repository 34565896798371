import React from "react";
import { Input, Button, Row, Select } from "antd";

const { Option } = Select;

const ProductPrices = ({ prices, setPrices }) => {
  const handleRowsChange = (event) => {
    const _tempPrices = [...prices];
    _tempPrices[event.target.dataset.id][event.target.name] =
      event.target.value;
    _tempPrices[event.target.dataset.id].date = new Date();
    setPrices(_tempPrices);
  };

  const addNewRow = () => {
    setPrices((prices) => [...prices, {}]);
  };

  const handleCurrencyChange = (value, event) => {
    const _tempPrices = [...prices];
    _tempPrices[parseInt(event.key)].currency = value;
    setPrices(_tempPrices)
  };

  return (
    <div>
      <Row type="flex" justify="center" align="middle">
        <table>
          <thead>
            <tr>
              <th>#</th>
              <th>Price:</th>
              <th>Date:</th>
            </tr>
          </thead>
          <tbody>
            {prices &&
              prices.map((item, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>
                    <Input
                      name="price"
                      data-id={index}
                      disabled={index === prices.length - 1 ? false : true}
                      type="number"
                      value={item.price}
                      onChange={handleRowsChange}
                    />
                  </td>
                  <td>
                    <Input
                      name="date"
                      data-id={index}
                      type="text"
                      disabled
                      value={item.date}
                      onChange={handleRowsChange}
                    />
                  </td>
                  <td>
                    <Select
                      value={item.currency}
                      name="currency"
                      onChange={handleCurrencyChange}
                    >
                      <Option key={index} value="AMD">
                        AMD
                      </Option>
                      <Option key={index} value="USD">
                        USD
                      </Option>
                    </Select>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </Row>
      <Row type="flex" justify="center" align="middle">
        <Button shape="circle" onClick={addNewRow}>
          +
        </Button>
      </Row>
    </div>
  );
};

export default ProductPrices;
