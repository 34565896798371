import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  List,
  Layout,
  Button,
  Row,
  Col,
  Input,
  Tabs,
  Icon,
  message,
} from "antd";
import ListSort from "../Utils/ListSort";
import api from "../../Api";
import ExcelProducts from "./ExcelProducts";

const { Content } = Layout;
const { Search } = Input;
const { TabPane } = Tabs;

const ProductsDataView = () => {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [prices, setPrices] = useState([]);
  const [features, setFeatures] = useState([]);
  const [categories, setCategories] = useState([]);
  const [manufacturers, setManufacturers] = useState([]);
  const [specs, setSpecs] = useState([]);
  const [ordering, setOrdering] = useState([]);

  useEffect(() => {
    const getProducts = async () => {
      let pricesArray = [];
      let featuresArray = [];
      await api
        .products()
        .get()
        .then((res) => {
          setProducts(res.data);
          res.data.forEach((product) => {
            product.features &&
              product.features.forEach((feature) => {
                featuresArray.push({
                  product: product.id,
                  selected: feature.selected,
                  description: feature.description,
                });
              });
          });
          setFeatures(featuresArray);
          setLoading(false);
        })
        .then(
          api
            .prices()
            .get()
            .then((res) => {
              res.data.forEach((item) => {
                item.price.forEach((el) => {
                  pricesArray.push({
                    product: item.id,
                    priceDate: el.date,
                    priceSum: el.price,
                  })
                })
              })
              setPrices(pricesArray);
            })
        );
    };
    getProducts();
  }, []);
  useEffect(() => {
    const getCategories = async () => {
      const { data } = await api.categories().get();
      setCategories(data.filter((x) => x.parentId !== 0));
    };
    const getManufacturers = async () => {
      const { data } = await api.manufacturers().get();
      setManufacturers(data);
    };
    const getSpecs = async () => {
      const { data } = await api.specs().get();
      setSpecs(data.sort((x, y) => (x.name > y.name ? 1 : -1)));
    };
    getCategories();
    getManufacturers();
    getSpecs();
  }, []);

  const handleOnSearch = (value) => {
    api
      .products()
      .get()
      .then((response) => {
        if (!value) {
          setProducts(response.data);
        } else {
          setProducts(
            products.filter((product) =>
              product.name.toUpperCase().includes(value.toUpperCase())
            )
          );
        }
      });
  };

  const handleSaveOrdering = async () => {
    ordering.forEach((item, index) => {
      api.products().post({ id: item.props.product, ordering: index });
    });
    await message.success("Ordering successfully edited", 1);
    window.location.reload();
  };

  const sortableProducts = products
    .filter((x) => x.favorite)
    .sort((a, b) => a.ordering - b.ordering)
    .map((item, i) => {
      return (
        <div key={i} product={item.id} className="list-sort-demo-list">
          <div className="list-sort-demo-icon">
            <Icon type="drag" />
          </div>
          <div className="list-sort-demo-text">
            <h1>{item.code}</h1>
            <p>{item.name}</p>
          </div>
        </div>
      );
    });
  const handleSortChange = (e) => {
    setOrdering(e);
  };

  return (
    <Tabs defaultActiveKey="1">
      <TabPane tab="All Products" key="1">
        <Content style={{ margin: "24px 16px 0", overflow: "initial" }}>
          <Row type="flex" justify="space-between" align="middle">
            <Col span={6}>
              <h1>All Products</h1>
            </Col>
            <Col span={12}>
              <Search
                type="text"
                name="search"
                enterButton="Search"
                placeholder="input product name"
                onSearch={handleOnSearch}
              />
            </Col>
            <Col span={6}>
              <div className="addNew">
                <ExcelProducts
                  products={products}
                  prices={prices}
                  categories={categories}
                  manufacturers={manufacturers}
                  specs={specs}
                  features={features}
                />
                <Link to={{ pathname: `/newproduct/` }}>
                  <Button
                    type="primary"
                    style={{ margin: "10px 0" }}
                    icon="plus"
                    size="large"
                  >
                    Add new
                  </Button>
                </Link>
              </div>
            </Col>
          </Row>
          <div
            style={{
              padding: 24,
              background: "#fff",
              textAlign: "left",
              borderRadius: 12,
            }}
          >
            <List
              itemLayout="horizontal"
              loading={loading}
              pagination={{
                onChange: (page) => {
                  console.log(page);
                },
                pageSize: 20,
              }}
              dataSource={products}
              renderItem={(product) => (
                <List.Item key={product.id}>
                  <h2>{product.code}</h2>
                  <List.Item.Meta title={product.name} />
                  <Link to={{ pathname: `/product/?productID=${product.id}` }}>
                    <Button>Show/Edit</Button>
                  </Link>
                </List.Item>
              )}
            />
          </div>
        </Content>
      </TabPane>
      <TabPane tab="Favorites" key="2">
        <Content style={{ margin: "24px 16px 0", overflow: "initial" }}>
          <Row type="flex" justify="space-between" align="middle">
            <Col span={6}>
              <h1>Favorites</h1>
            </Col>
          </Row>
          <div
            style={{
              padding: 24,
              background: "#fff",
              textAlign: "left",
              borderRadius: 12,
            }}
          >
            <div className="list-sort-demo-wrapper ">
              <div className="list-sort-demo">
                <Row type="flex" justify="end" align="middle">
                  <Button
                    type="primary"
                    icon="save"
                    size="large"
                    onClick={handleSaveOrdering}
                  >
                    Save
                  </Button>
                </Row>
                <ListSort
                  dragClassName="list-drag-selected"
                  appearAnim={{
                    animConfig: { marginTop: [5, 30], opacity: [1, 0] },
                  }}
                  onChange={handleSortChange}
                >
                  {sortableProducts}
                </ListSort>
              </div>
            </div>
          </div>
        </Content>
      </TabPane>
    </Tabs>
  );
};
export default ProductsDataView;
