import React, { useState, useEffect } from "react";
import { Input, Button, Row, Select } from "antd";
import api from "./../../Api";

const { Option } = Select;

const ProductFeatures = ({ rows, setRows }) => {
  const [specs, setSpecs] = useState([]);

  useEffect(() => {
    const getSpecs = async () => {
      const res = await api.specs().get();
      setSpecs(res.data);
    };
    getSpecs();
  }, []);

  const handleRowsChange = (event) => {
    const _tempRows = [...rows];
    _tempRows[event.target.dataset.id][event.target.name] = event.target.value;
    setRows(_tempRows);
  };

  const addNewRow = () => {
    setRows((prevRows) => [...prevRows, { description: "" }]);
  };

  const handleRemoveSpecificRow = (index) => () => {
    rows.splice(index, 1);
    setRows((rows) => [...rows]);
  };

  const handleFeatureSelect = (index) => (value) => {
    const _tempRows = [...rows];
    _tempRows[index].selected = value;
    setRows(_tempRows);
  };

  return (
    <div>
      <Row type="flex" justify="center" align="middle">
        <table>
          <thead>
            <tr>
              <th>#</th>
              <th>Name:</th>
              <th>Description:</th>
            </tr>
          </thead>
          <tbody>
            {rows.map((item, index) => (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>
                  <Select
                    showSearch
                    onChange={handleFeatureSelect(index)}
                    value={item.selected}
                    placeholder="Select a specification"
                    optionFilterProp="spec"
                    filterOption={(input, option) =>
                      option.props.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {specs.map((spec) => (
                      <Option value={spec.id} key={spec.id}>
                        {spec.name}
                      </Option>
                    ))}
                  </Select>
                </td>
                <td>
                  <Input
                    name="description"
                    data-id={index}
                    type="text"
                    value={item.description}
                    onChange={handleRowsChange}
                  />
                </td>
                <td>
                  <Button
                    type="danger"
                    shape="circle"
                    onClick={handleRemoveSpecificRow(index)}
                  >
                    -
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </Row>
      <Row type="flex" justify="center" align="middle">
        <Button shape="circle" onClick={addNewRow}>
          +
        </Button>
      </Row>
    </div>
  );
};

export default ProductFeatures;
