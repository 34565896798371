import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Layout, Button, Row, Col, Collapse } from "antd";
import api from "./../../Api";

const { Content } = Layout;
const { Panel } = Collapse;

const CategoriesDataView = () => {
  const [categories, setCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);

  useEffect(() => {
    const getCategories = async () => {
      const { data } = await api.categories().get();
      setSubCategories(data.filter(x => x.parentId !== 0));
      setCategories(data);
    };
    getCategories();
  }, []);

  const callback = key => {
    console.log(key);
  };

  return (
    <Content style={{ margin: "24px 16px 0", overflow: "initial" }}>
      <Row type="flex" justify="space-between" align="middle">
        <Col span={12}>
          <h1>Categories</h1>
        </Col>
        <Col span={12}>
          <div className="addNew">
            <Link to={{ pathname: "/newcategory/" }}>
              <Button type="primary" icon="plus" size="large">
                Add new
              </Button>
            </Link>
          </div>
        </Col>
      </Row>
      <div
        style={{
          padding: 24,
          background: "#fff",
          textAlign: "left",
          borderRadius: 12
        }}
      >
        {categories.map(parent =>
          parent.parentId === 0 ? (
            <Collapse onChange={callback} style={{ marginBottom: 12 }}>
              <Panel
                header={parent.code + " - " + parent.name}
                key={parent.id}
                disabled={
                  categories.filter(x => x.parentId === parent.id).length === 0
                }
                extra={
                  <Link
                    to={{
                      pathname: `/category/?categoryID=${parent.id}`
                    }}
                  >
                    <Button>Show/Edit</Button>
                  </Link>
                }
              >
                <ul>
                  {subCategories
                    .filter(x => x.parentId === parent.id)
                    .map(child => (
                      <div>
                        <li key={child.id} style={{ listStyle: "none" }}>
                          <Row
                            style={{ borderBottom: "1px solid #d9d9d9" }}
                            type="flex"
                            justify="space-between"
                            align="middle"
                          >
                            <h2>
                              {child.code} - {child.name}
                            </h2>
                            <Link
                              to={{
                                pathname: `/category/?categoryID=${child.id}`
                              }}
                            >
                              <Button>Show/Edit</Button>
                            </Link>
                          </Row>
                        </li>
                        <div>
                          {subCategories
                            .filter(x => x.parentId === child.id)
                            .map(subChild => (
                              <li
                                key={subChild.id}
                                style={{ listStyle: "none" }}
                              >
                                <Row
                                  style={{ borderBottom: "1px solid #d9d9d9" }}
                                  type="flex"
                                  justify="space-between"
                                  align="middle"
                                >
                                  <h2>
                                    {child.code} - {child.name} ->
                                    {subChild.code} - {subChild.name}
                                  </h2>
                                  <Link
                                    to={{
                                      pathname: `/category/?categoryID=${subChild.id}`
                                    }}
                                  >
                                    <Button>Show/Edit</Button>
                                  </Link>
                                </Row>
                              </li>
                            ))}
                        </div>
                      </div>
                    ))}
                </ul>
              </Panel>
            </Collapse>
          ) : (
            ""
          )
        )}
      </div>
    </Content>
  );
};
export default CategoriesDataView;
