import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { Layout, Button, Row, Col } from "antd";
import api from "./../Api";
import Cookie from "js-cookie";
const { Header } = Layout;
const API_URL = process.env.REACT_APP_API_URL;

const AppBar = () => {
  const [logo, setLogo] = useState();
  const [isLoggedIn, setIsLoggedIn] = useState(
    Cookie.get("jwtToken") !== "" ? true : false
  );
  const history = useHistory();

  useEffect(() => {
    const getLogo = async () => {
      const { data } = await api.informations().get();
      if (data[0].logoUrl !== "") {
        setLogo(`${API_URL}/${data[0].logoUrl}`);
      }
    };
    getLogo();
  }, []);

  const onClick = () => {
    Cookie.set("jwtToken", "");
    setIsLoggedIn(false);
    history.push("/login");
    window.location.reload();
  };
  return (
    <Header style={{ background: "#fff", padding: 12 }}>
      <Row type="flex" align="middle" justify="space-between">
        <Col style={{ margin: "0 16px", overflow: "initial" }}>
          {logo && (
            <img
              style={{ width: "130px", borderRadius: "10px" }}
              src={logo}
              alt="Client Logo"
            />
          )}
        </Col>
        <Col style={{ margin: "0 16px", overflow: "initial" }}>
          {isLoggedIn && Cookie.get("expTime") >= Date.now() / 1000 ? (
            <div className="profile">
              <h3>{Cookie.get("username")}</h3>
              <Button onClick={onClick}>Logout</Button>
            </div>
          ) : (
            <Link to="/login">
              <Button>Login</Button>
            </Link>
          )}
        </Col>
      </Row>
    </Header>
  );
};

export default AppBar;
