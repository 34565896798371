import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Row, Input, Button, Form, Layout, Col, message } from "antd";
import api from "../../Api";

const { Content } = Layout;
const { TextArea } = Input;

const useUpdateForm = (defaultValues) => {
  const [inputs, setInputs] = useState({ ...defaultValues });
  const history = useHistory();

  const handleSubmit = (event) => {
    event.preventDefault();
    let search = window.location.search;
    let params = new URLSearchParams(search);
    let categoryID = params.get("categoryID");
    let data = {
      id: parseInt(categoryID),
      code: inputs.code ?? "",
      name: inputs.name ?? "",
      description: inputs.description ?? "",
    };
    api
      .categories()
      .post(data)
      .then(async (res) => {
        await message.success("This category successfully added", 1);
        history.push(`/category/?categoryID=${res.data.id}`);
        window.location.reload();
      })
      .catch((error) => console.log(error));
  };
  const handleInputChange = (event) => {
    event.persist();
    setInputs((inputs) => ({
      ...inputs,
      [event.target.name]: event.target.value,
    }));
  };

  return {
    handleSubmit,
    handleInputChange,
    inputs,
  };
};

const NewCategoryForm = ({ category }) => {
  const { inputs, handleInputChange, handleSubmit } = useUpdateForm(category);

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Item label="Code">
        <Input
          type="text"
          name="code"
          onChange={handleInputChange}
          value={inputs.code}
        />
      </Form.Item>
      <Form.Item label="Category name">
        <Input
          type="text"
          name="name"
          onChange={handleInputChange}
          value={inputs.name}
        />
      </Form.Item>
      <Form.Item label="Description">
        <TextArea
          type="text"
          name="description"
          autosize={{ minRows: 3, maxRows: 50 }}
          onChange={handleInputChange}
          value={inputs.description}
        />
      </Form.Item>
      <Form.Item>
        <Button htmlType="submit">Create</Button>
      </Form.Item>
    </Form>
  );
};

const NewCategory = () => {
  const [category, setCategory] = useState({});

  useEffect(() => {
    const fetchCategory = async () => {
      let search = window.location.search;
      let params = new URLSearchParams(search);
      let categoryID = params.get("categoryID");

      const res = await api.categories(categoryID).get();
      setCategory(res.data[0]);
    };
    fetchCategory();
  }, []);
  return (
    <div className="category">
      <Layout>
        <Content style={{ padding: "32px 50px" }}>
          <Row>
            <Col span={12}>
              <div>
                <h1>Create new category</h1>
                <NewCategoryForm category={category} />
              </div>
            </Col>
            <Col span={12}></Col>
          </Row>
        </Content>
      </Layout>
    </div>
  );
};
export default NewCategory;
