import React from "react";
import { Link } from "react-router-dom";
import { Layout, Row, Col, Card } from "antd";

const { Content } = Layout;

const App = () => {
  return (
    <Content style={{ margin: "24px 16px 0", overflow: "initial" }}>
      <div style={{ padding: 24, textAlign: "center" }}>
        <Row gutter={16}>
          <Col span={6}>
            <Link to="/products">
              <Card
                title="Products"
                bordered={false}
                style={{ borderRadius: 12 }}
              >
                <p>Click here to see products list</p>
              </Card>
            </Link>
          </Col>
          <Col span={6}>
            <Link to="/manufacturers">
              <Card
                title="Manufacturers"
                bordered={false}
                style={{ borderRadius: 12 }}
              >
                <p>Click here to see manufacturers list</p>
              </Card>
            </Link>
          </Col>
          <Col span={6}>
            <Link to="/specs">
              <Card
                title="Specification"
                bordered={false}
                style={{ borderRadius: 12 }}
              >
                <p>Click here to see product specs</p>
              </Card>
            </Link>
          </Col>
          <Col span={6}>
            <Link to="/orders">
              <Card
                title="Orders"
                bordered={false}
                style={{ borderRadius: 12 }}
              >
                <p>Click here to see orders</p>
              </Card>
            </Link>
          </Col>
        </Row>
        <Row gutter={16} style={{ marginTop: 32 }}>
          <Col span={6}>
            <Link to="/categories">
              <Card
                title="Categories"
                bordered={false}
                style={{ borderRadius: 12 }}
              >
                <p>Click here to see categories list</p>
              </Card>
            </Link>
          </Col>
          <Col span={6}>
            <Link to="/pages">
              <Card title="Pages" bordered={false} style={{ borderRadius: 12 }}>
                <p>Click here to see pages list</p>
              </Card>
            </Link>
          </Col>
          <Col span={6}>
            <Link to="/posts">
              <Card title="Posts" bordered={false} style={{ borderRadius: 12 }}>
                <p>Click here to see post list</p>
              </Card>
            </Link>
          </Col>
        </Row>
      </div>
    </Content>
  );
};

export default App;
