import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
  Row,
  Input,
  Button,
  Form,
  Select,
  Layout,
  Col,
  message,
  Upload,
  Icon,
  Modal,
  Divider,
  Tabs,
  Checkbox,
  Card,
  Spin,
} from "antd";
import FormData from "form-data";
import ProductFeatures from "./ProductFeatures";
import Editor from "../Editor";
import languages from "../Languages/languages.json";
import slugify from "slugify";
import api from "./../../Api";
import ProductPrices from "./ProductPrices";
import { ProductNameExists, ProductCodeExists } from "../Utils/Validator";

const { Option } = Select;
const { Content } = Layout;
const API_URL = process.env.REACT_APP_API_URL;
const { TabPane } = Tabs;

//show image
const getBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
};

//get categories and manufacturers
const useCategories = () => {
  const [categories, setCategories] = useState([]);
  const [manufacturers, setManufacturers] = useState([]);

  useEffect(() => {
    const getCategories = async () => {
      const { data } = await api.categories().get();
      setCategories(data);
    };
    const getManufacturers = async () => {
      const { data } = await api.manufacturers().get();
      setManufacturers(data);
    };
    getCategories();
    getManufacturers();
  }, []);
  return {
    categories,
    manufacturers,
  };
};

//form updates and submit, get product stored data
const useUpdateForm = (defaultValues) => {
  const [inputs, setInputs] = useState({ ...defaultValues });
  const [translatedDescription, setTranslatedDescription] = useState(
    inputs.translate ? inputs.translate : ""
  );
  const [showDefaultImgButton, setShowDefImgButton] = useState(true);
  const [images, setImages] = useState([]);
  const [oldImages, setOldImages] = useState([]);
  const [modal, setModal] = useState({
    ModalText: "Confirm product delete",
    visible: false,
    confirmLoading: false,
  });
  const [prices, setPrices] = useState(
    inputs.prices
      ? inputs.prices
      : []
  );
  const [rows, setRows] = useState(
    inputs.features && inputs.features !== null
      ? inputs.features
      : [{ description: "" }]
  );

  const [description, setDescription] = useState(inputs.description);

  //get images
  useEffect(() => {
    const ImagesUrls = async () => {
      //get product images
      const productImagesUrls = [];
      let search = window.location.search;
      let params = new URLSearchParams(search);
      let productID = params.get("productID");
      await api
        .images()
        .get()
        .then((res) =>
          res.data.forEach((img) => {
            if (img.productId === parseInt(productID)) {
              productImagesUrls.push({
                uid: img.id,
                name: "",
                status: `${img.isDefault ? "error" : "done"}`,
                url: `${API_URL}/${img.url}`,
                isDefault: img.isDefault,
              });
            }
          })
        );
      setImages((images) => ({ ...images, fileList: productImagesUrls }));
      setOldImages(productImagesUrls);
    };
    ImagesUrls();
  }, []);

  //get prices
  useEffect(() => {
    let search = window.location.search;
    let params = new URLSearchParams(search);
    let productID = params.get("productID");

    const getPrices = async () => {
      await api
        .prices(productID)
        .get()
        .then((res) => res.data.find((item) => item.prices ? setPrices(item.prices) : ""));
    };
    getPrices();
  }, []);

  const handleSubmit = async (event) => {
    if (event) {
      event.preventDefault();
    }
    if ((await ProductNameExists(inputs.name, inputs.id)) > 0) {
      await message.error(`"${inputs.name}" product name already exists.`, 2);
      return;
    }
    if ((await ProductCodeExists(inputs.code, inputs.id)) > 0) {
      await message.error(`"${inputs.code}" product code already exists.`, 2);
      return;
    }
    let search = window.location.search;
    let params = new URLSearchParams(search);
    let productID = params.get("productID");

    let formData = new FormData();
    if (!!images.fileList) {
      for (let i = 0; i < images.fileList.length; i++) {
        formData.append("image", images.fileList[i].originFileObj);
      }
      await api
        .uploads()
        .post(formData)
        .then((res) => {
          res.data.forEach((res) => {
            api.images().post({
              productId: parseInt(productID),
              url: res.path,
            });
          });
        });
    }
    // get images differences
    let intersection = oldImages.filter((x) => !images.fileList.includes(x));
    for (let i = 0; i < intersection.length; i++) {
      let uid = intersection[i].uid;
      await api.images(uid).delete();
    }
    //post product data
    const data = {
      id: parseInt(productID),
      code: inputs.code ?? "",
      name: inputs.name ?? "",
      description: description,
      categoryId: inputs.categoryId ?? 0,
      manufacturerId: inputs.manufacturerId ?? 0,
      features: rows,
      prices: prices.filter((x) => x.price),
      translate: translatedDescription,
      isActive: inputs.isActive ?? false,
      favorite: inputs.favorite ?? false,
      inStock: inputs.inStock ?? false,
      byOrder: inputs.byOrder ?? false,
      youtube: inputs.youtube ?? "",
      slug: slugify(inputs.name, {
        lower: true,
      }),
    };
    await api
      .products()
      .post(data)
      .then(async (res) => {
        await message.success("This product successfully edited", 1);
        window.location.reload();
      });
  };
  const history = useHistory();

  const showModal = () => {
    setModal((modal) => ({
      ...modal,
      visible: true,
    }));
  };
  const handleModalOk = async () => {
    setModal((modal) => ({
      ...modal,
      ModalText: "Please wait...",
      confirmLoading: true,
    }));
    setTimeout(() => {
      setModal((modal) => ({
        ...modal,
        visible: false,
        confirmLoading: false,
      }));
    }, 2000);
    await api.products(inputs.id).delete();
    await message.warning("This product successfully deleted", 1);
    history.push(`/products`);
  };

  const handleModalCancel = () => {
    setModal((modal) => ({
      ...modal,
      visible: false,
    }));
  };

  const handleInputChange = (event) => {
    event.persist();
    setInputs((inputs) => ({
      ...inputs,
      [event.target.name]: event.target.value,
    }));
  };

  const handleCategoryChange = (value) => {
    setInputs((inputs) => ({
      ...inputs,
      categoryId: value,
    }));
  };

  const handleManufacturerChange = (value) => {
    setInputs((inputs) => ({
      ...inputs,
      manufacturerId: value,
    }));
  };

  const handleCheckBoxChange = (event) => {
    setInputs((inputs) => ({
      ...inputs,
      isActive: event.target.checked,
    }));
  };
  const handleFavoriteBoxChange = (event) => {
    setInputs((inputs) => ({
      ...inputs,
      favorite: event.target.checked,
    }));
  };
  const handleInStockBoxChange = (event) => {
    setInputs((inputs) => ({
      ...inputs,
      inStock: event.target.checked,
    }));
  };
  const handleByOrderBoxChange = (event) => {
    setInputs((inputs) => ({
      ...inputs,
      byOrder: event.target.checked,
    }));
  };
  const handleCancel = () => {
    setImages((images) => ({ ...images, previewVisible: false }));
  };

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setImages((images) => ({
      ...images,
      previewImage: file.url || file.preview,
      previewVisible: true,
      previewDefault: file.isDefault,
    }));
    if (typeof file.uid !== "number") {
      setShowDefImgButton(false);
    } else {
      setShowDefImgButton(true);
    }
  };

  const handleImageChange = ({ fileList }) => {
    setImages((images) => ({ ...images, fileList }));
  };

  return {
    handleSubmit,
    handleModalOk,
    handleModalCancel,
    showModal,
    modal,
    handleInputChange,
    inputs,
    handleCategoryChange,
    handleManufacturerChange,
    handleCheckBoxChange,
    handleFavoriteBoxChange,
    handleInStockBoxChange,
    handleByOrderBoxChange,
    handleImageChange,
    handleCancel,
    handlePreview,
    images,
    setImages,
    rows,
    setRows,
    prices,
    showDefaultImgButton,
    setPrices,
    description,
    setDescription,
    translatedDescription,
    setTranslatedDescription,
  };
};
//Product form component
const ProductForm = ({ product, categories, manufacturers }) => {
  const {
    inputs,
    handleInputChange,
    handleCategoryChange,
    handleManufacturerChange,
    handleCheckBoxChange,
    handleFavoriteBoxChange,
    handleInStockBoxChange,
    handleByOrderBoxChange,
    modal,
    handleImageChange,
    handleSubmit,
    handleModalOk,
    handleModalCancel,
    showModal,
    handleCancel,
    handlePreview,
    images,
    setImages,
    rows,
    setRows,
    prices,
    setPrices,
    description,
    setDescription,
    showDefaultImgButton,
    translatedDescription,
    setTranslatedDescription,
  } = useUpdateForm(product);

  const uploadButton = (
    <div>
      <Icon type="plus" />
      <div className="ant-upload-text">Upload</div>
    </div>
  );

  const onSetGeneral = async () => {
    const defImg = images.fileList.filter(
      (el) => el.url === images.previewImage
    );
    const oldDefImg = images.fileList.filter((el) => el.isDefault === true);
    if (oldDefImg.length > 0) {
      await api.images().post({
        id: oldDefImg[0].uid,
        isDefault: false,
      });
    }
    await api.images().post({
      id: defImg[0].uid,
      isDefault: true,
    });
    setImages({ ...images, previewVisible: false });
    window.location.reload();
  };
  return (
    <Form onSubmit={handleSubmit}>
      <Row type="flex" justify="space-between" align="top" gutter={16}>
        <Col span={10}>
          <Form.Item label="Code">
            <Input
              type="text"
              name="code"
              onChange={handleInputChange}
              value={inputs.code}
            />
          </Form.Item>
          <Form.Item label="Product name">
            <Input
              type="text"
              name="name"
              onChange={handleInputChange}
              value={inputs.name}
            />
          </Form.Item>
          <Form.Item label="Category">
            <Select
              showSearch
              name="categoryId"
              style={{ width: "100%" }}
              placeholder="Please select category"
              onChange={handleCategoryChange}
              value={inputs.categoryId}
              filterOption={(input, option) =>
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
            >
              <Option value={0}>None</Option>
              {categories.map((cat) => (
                <Option value={cat.id} key={cat.id}>
                  {cat.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item label="Manufacturer">
            <Select
              showSearch
              name="manufacturerId"
              style={{ width: "100%" }}
              placeholder="Please select manufacturer"
              onChange={handleManufacturerChange}
              value={inputs.manufacturerId}
              filterOption={(input, option) =>
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
            >
              <Option value={0}>None</Option>
              {manufacturers.map((manuf) => (
                <Option value={manuf.id} key={manuf.id}>
                  {manuf.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item label="Slug">
            <Input
              name="slug"
              type="text"
              onChange={handleInputChange}
              value={inputs.slug}
              disabled
            />
          </Form.Item>
          <Col span={12}>
            <Form.Item>
              <Checkbox
                checked={inputs.isActive}
                onChange={handleCheckBoxChange}
              >
                Is Active
              </Checkbox>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item>
              <Checkbox
                checked={inputs.favorite}
                onChange={handleFavoriteBoxChange}
              >
                Favorite
              </Checkbox>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item>
              <Checkbox
                checked={inputs.inStock}
                onChange={handleInStockBoxChange}
              >
                In stock
              </Checkbox>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item>
              <Checkbox
                checked={inputs.byOrder}
                onChange={handleByOrderBoxChange}
              >
                By order
              </Checkbox>
            </Form.Item>
          </Col>
          <Divider />
          <Form.Item>
            <div className="clearfix">
              <Upload
                action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                accept=".jpg, .jpeg, .png, .gif"
                listType="picture-card"
                fileList={images.fileList}
                onPreview={handlePreview}
                onChange={handleImageChange}
                style={{ borderColor: "orange" }}
              >
                {!!images.fileList && images.fileList.length >= 8
                  ? null
                  : uploadButton}
              </Upload>
              <Modal
                visible={images.previewVisible}
                footer={null}
                onCancel={handleCancel}
              >
                <img
                  alt="example"
                  style={{ width: "100%" }}
                  src={images.previewImage}
                />
                {showDefaultImgButton ? (
                  <Button
                    disabled={images.previewDefault ? true : false}
                    onClick={onSetGeneral}
                  >
                    Set General
                  </Button>
                ) : (
                  ""
                )}
              </Modal>
            </div>
          </Form.Item>
          <Form.Item label="YouTube link">
            <Input
              type="text"
              name="youtube"
              onChange={handleInputChange}
              value={inputs.youtube}
              placeholder="paste here YouTube link"
            />
          </Form.Item>
        </Col>
        <Col span={14}>
          <Row>
            <Col span={24}>
              <Card
                title="Features"
                bordered={false}
                style={{ borderRadius: 12 }}
              >
                <ProductFeatures rows={rows} setRows={setRows} />
              </Card>
            </Col>
            <Divider />
            <Col span={24}>
              <Card
                title="Prices"
                bordered={false}
                style={{ borderRadius: 12 }}
              >
                <ProductPrices prices={prices} setPrices={setPrices} />
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row type="flex" justify="center" align="middle">
        <Form.Item label="Description" style={{ width: "100%" }}>
          <Tabs defaultActiveKey="1">
            {languages.map((language) => {
              return (
                <TabPane tab={language.name} key={language.id}>
                  <Editor
                    description={description}
                    setDescription={setDescription}
                    languageId={language.id}
                    translatedDescription={translatedDescription}
                    setTranslatedDescription={setTranslatedDescription}
                  />
                </TabPane>
              );
            })}
          </Tabs>
        </Form.Item>
        <Divider />
        <Row type="flex" gutter={32}>
          <Col>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                Update
              </Button>
            </Form.Item>
          </Col>
          <Col>
            <Form.Item>
              <Button type="danger" onClick={showModal}>
                Delete
              </Button>
              <Modal
                title="Are you sure?"
                visible={modal.visible}
                onOk={handleModalOk}
                confirmLoading={modal.confirmLoading}
                onCancel={handleModalCancel}
              >
                <p>{modal.ModalText}</p>
              </Modal>
            </Form.Item>
          </Col>
        </Row>
      </Row>
    </Form>
  );
};
//Product component
const Product = () => {
  const { categories, manufacturers } = useCategories();
  const [product, setProduct] = useState({});
  const [spinning, setSpinning] = useState(true);

  useEffect(() => {
    const fetchProduct = async () => {
      let search = window.location.search;
      let params = new URLSearchParams(search);
      let productID = params.get("productID");
      await api
        .products(productID)
        .get()
        .then((res) => {
          setProduct(res.data[0]);
          setSpinning(false);
        })
        .catch((error) => console.log(error));
    };
    fetchProduct();
  }, []);

  return (
    <div className="product">
      <Spin spinning={spinning} delay={300}>
        <Layout>
          <Content style={{ padding: "32px 50px" }}>
            <h1>{product.name}</h1>
            <Divider />
            {!!product.id && (
              <ProductForm
                product={product}
                categories={categories}
                manufacturers={manufacturers}
              />
            )}
          </Content>
        </Layout>
      </Spin>
    </div>
  );
};
export default Product;
