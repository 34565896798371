import api from "../../Api";

export const ProductNameExists = async (productName, excludeId) => {
  let productNameExist = 0;
  await api
    .products()
    .get()
    .then((response) => {
      if (excludeId === null) {
        productNameExist = response.data.filter(
          (x) =>
            x.name.trim().toUpperCase() === productName.trim().toUpperCase()
        ).length;
      } else {
        productNameExist = response.data.filter(
          (x) =>
            x.id !== excludeId &&
            x.name.trim().toUpperCase() === productName.trim().toUpperCase()
        ).length;
      }
    });
  return productNameExist;
};

export const ProductCodeExists = async (productCode, excludeId) => {
  let productCodeExist = 0;
  await api
    .products()
    .get()
    .then((response) => {
      if (excludeId === null) {
        productCodeExist = response.data.filter(
          (x) =>
            x.code.trim().toUpperCase() === productCode.trim().toUpperCase()
        ).length;
      } else {
        productCodeExist = response.data.filter(
          (x) =>
            x.id !== excludeId &&
            x.code.trim().toUpperCase() === productCode.trim().toUpperCase()
        ).length;
      }
    });
  return productCodeExist;
};

export const AreaExists = async (area, excludeId) => {
  let areaExists = 0;
  await api
    .geoZones()
    .get()
    .then((response) => {
      if (excludeId === null) {
        areaExists = response.data.filter(
          (x) =>
            x.area.trim().toUpperCase() === area.trim().toUpperCase()
        ).length;
      } else {
        areaExists = response.data.filter(
          (x) =>
            x.id !== excludeId &&
            x.area.trim().toUpperCase() === area.trim().toUpperCase()
        ).length;
      }
    });
  return areaExists;
};