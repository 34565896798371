import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Form,
  Icon,
  Input,
  Button,
  Checkbox,
  Row,
  Col,
  Layout,
  Alert
} from "antd";
import Cookie from "js-cookie";

const { Content } = Layout;
const API_URL = process.env.REACT_APP_API_URL

const parseJwt = token => {
  let base64Url = token.split(".")[1];
  let base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  let jsonPayload = decodeURIComponent(
    atob(base64)
      .split("")
      .map(function(c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );
  return JSON.parse(jsonPayload);
};

const AuthenticationUI = props => {
  const [profileState, setProfileState] = useState(props);
  const [loginError, setLoginError] = useState({});
  useEffect(() => {
    setProfileState(props);
  }, [props]);

  const handleSubmit = e => {
    e.preventDefault();
    profileState.form.validateFields((err, values) => {
      if (!err) {
        const loginUrl = `${API_URL}/auth/login/`;
        axios
          .post(loginUrl, {
            username: values.username,
            password: values.password
          })
          .then(async response => {
            console.log(response);
            if (response.status === 200 && response.data.token) {
              Cookie.set("jwtToken", response.data.token);
              Cookie.set("expTime", parseJwt(response.data.token).exp);
              Cookie.set("username", parseJwt(response.data.token).username);
              props.history.push("/");
              window.location.reload();
            }
          })
          .catch(error => {
            setLoginError(error.response.data);
          });
      }
    });
  };

  const { getFieldDecorator } = profileState.form;
  return (
    <Content style={{ margin: "24px 16px 0", overflow: "initial" }}>
      <div
        style={{
          padding: 24,
          textAlign: "center",
          background: "#ffffff",
          borderRadius: 12
        }}
      >
        <Row gutter={16} type="flex" justify="center">
          <Col span={6}>
            <Form onSubmit={handleSubmit} className="login-form">
              <Form.Item>
                {getFieldDecorator("username", {
                  rules: [
                    { required: true, message: "Please input your username!" }
                  ]
                })(
                  <Input
                    prefix={
                      <Icon type="user" style={{ color: "rgba(0,0,0,.25)" }} />
                    }
                    placeholder="Username"
                    name="username"
                  />
                )}
              </Form.Item>
              <Form.Item>
                {getFieldDecorator("password", {
                  rules: [
                    { required: true, message: "Please input your Password!" }
                  ]
                })(
                  <Input
                    prefix={
                      <Icon type="lock" style={{ color: "rgba(0,0,0,.25)" }} />
                    }
                    type="password"
                    placeholder="Password"
                    name="password"
                  />
                )}
              </Form.Item>
              {Object.values(loginError).length > 0 ? (
                <Alert message={loginError.message} type="error" />
              ) : (
                ""
              )}
              {Cookie.get("expTime") < Date.now() / 1000 ? (
                <Alert
                  message="Session is expired, please login again"
                  type="warning"
                />
              ) : (
                ""
              )}
              <Form.Item>
                {getFieldDecorator("remember", {
                  valuePropName: "checked",
                  initialValue: true
                })(<Checkbox>Remember me</Checkbox>)}
                <Button
                  type="primary"
                  htmlType="submit"
                  className="login-form-button"
                >
                  Log in
                </Button>
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </div>
    </Content>
  );
};
const WrappedLoginForm = Form.create({ name: "normal_login" })(
  AuthenticationUI
);

export default WrappedLoginForm;
