import React, { useEffect, useState } from "react";
import { Layout, Divider, Button } from "antd";
import BaseBanner from "./BaseBanner";
import api from "../../Api";

const { Content } = Layout;
const bannersData = [
  {
    displayName: "Left Banner",
    name: "leftBanner",
  },
  {
    displayName: "Right Banner",
    name: "rightBanner",
  },
  {
    displayName: "Right Banner 2",
    name: "rightBanner2",
  },
  {
    displayName: "Bottom Banner",
    name: "bottomBanner",
  },
];

const Banners = () => {
  const [banners, setBanners] = useState([]);
  const [carouselData, setCarouselData] = useState([]);

  useEffect(() => {
    const carDataArray = [];
    const getBanners = async () => {
      let images = await api.images().get();
      setBanners(images.data);
      const filtered = images.data.filter((el) =>
        el.bannerName.includes("carouselBanner")
      );
      filtered.forEach((el) => {
        carDataArray.push({
          displayName: `Carousel Banner ${el.bannerName.substring(14)}`,
          name: el.bannerName,
        });
      });
      setCarouselData(carDataArray);
    };
    getBanners();
  }, []);

  const handleAddCarousel = () => {
    let lastCarousel = 0;
    if (carouselData && carouselData.length > 0) {
      let sortedCarousel = carouselData.sort(
        (a, b) => Number(b.name.substring(14)) - Number(a.name.substring(14))
      );
      lastCarousel = Number(sortedCarousel[0].name.substring(14));
    }
    setCarouselData([
      ...carouselData,
      {
        displayName: `Carousel Banner ${lastCarousel + 1}`,
        name: `carouselBanner${lastCarousel + 1}`,
      },
    ]);
  };

  return (
    <Content style={{ margin: "24px 16px 0", overflow: "initial" }}>
      <h1>Banners</h1>
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "space-evenly",
        }}
      >
        {banners.length > 0 &&
          bannersData.map((b) => {
            return (
              <BaseBanner
                style={{
                  padding: 24,
                  background: "#fff",
                  borderRadius: 12,
                  width: "48%",
                  margin: "1%",
                }}
                size={b.name === "bottomBanner" ? "896x320" : "350x600"}
                banners={banners}
                key={b.name}
                displayName={b.displayName}
                name={b.name}
              />
            );
          })}
      </div>
      <Divider />
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          alignItems: "center",
          justifyContent: "space-evenly",
        }}
      >
        {banners.length > 0 &&
          carouselData
            .sort(
              (a, b) =>
                Number(a.name.substring(14)) - Number(b.name.substring(14))
            )
            .map((c) => {
              return (
                <BaseBanner
                  style={{
                    padding: 24,
                    background: "#fff",
                    borderRadius: 12,
                    width: "30%",
                    margin: "1%",
                  }}
                  size={"896x320"}
                  banners={banners}
                  key={c.name}
                  displayName={c.displayName}
                  name={c.name}
                />
              );
            })}
        <Button
          onClick={handleAddCarousel}
          style={{
            height: "70px",
            width: "70px",
            fontSize: "30px",
            borderRadius: "50px",
          }}
        >
          +
        </Button>
      </div>
    </Content>
  );
};
export default Banners;
