import React from "react";
import { Link, useLocation } from "react-router-dom";
import { Layout, Menu, Icon } from "antd";
import logo from "../Images/logo.svg";

const { Sider } = Layout;

const SideBar = () => {
  let location = useLocation();
  return (
    <Sider
      style={{
        overflow: "auto",
        height: "100vh",
        position: "fixed",
        left: 0
      }}
    >
      <Link to="/">
        <div className="logo">
          <img src={logo} alt="logo" />
        </div>
      </Link>
      <Menu
        theme="dark"
        mode="inline"
        defaultSelectedKeys={[location.pathname.substring(1, 4)]}
      >
        <Menu.Item key="/">
          <Link to="/">
            <Icon type="dashboard" />
            <span className="nav-text">General</span>
          </Link>
        </Menu.Item>
        <Menu.Item key="pro">
          <Link to="/products">
            <Icon type="code-sandbox" />
            <span className="nav-text">Products</span>
          </Link>
        </Menu.Item>
        <Menu.Item key="cat">
          <Link to="/categories">
            <Icon type="container" />
            <span className="nav-text">Categories</span>
          </Link>
        </Menu.Item>
        <Menu.Item key="man">
          <Link to="/manufacturers">
            <Icon type="copyright" />
            <span className="nav-text">Manufacturers</span>
          </Link>
        </Menu.Item>
        <Menu.Item key="pag">
          <Link to="/pages">
            <Icon type="form" />
            <span className="nav-text">Pages</span>
          </Link>
        </Menu.Item>
        <Menu.Item key="pos">
          <Link to="/posts">
            <Icon type="read" />
            <span className="nav-text">Posts</span>
          </Link>
        </Menu.Item>
        <Menu.Item key="spe">
          <Link to="/specs">
            <Icon type="unordered-list" />
            <span className="nav-text">Specifications</span>
          </Link>
        </Menu.Item>
        <Menu.Item key="ord">
          <Link to="/orders">
            <Icon type="file-done" />
            <span className="nav-text">Orders</span>
          </Link>
        </Menu.Item>
        <Menu.Item key="ban">
          <Link to="/banners">
            <Icon type="picture" />
            <span className="nav-text">Banners</span>
          </Link>
        </Menu.Item>
        <Menu.Item key="set">
          <Link to="/settings">
            <Icon type="setting" />
            <span className="nav-text">Settings</span>
          </Link>
        </Menu.Item>
      </Menu>
    </Sider>
  );
};
export default SideBar;
