import React, { useState, useEffect } from "react";
import {
  Form,
  Input,
  Button,
  Select,
  InputNumber,
  Row,
  Col,
  List,
  message,
  Collapse,
  Modal,
  Spin,
  Tabs,
} from "antd";
import api from "../../Api";
import { AreaExists } from "../Utils/Validator";
import languages from "../Languages/languages.json";

const { Option } = Select;
const { Panel } = Collapse;
const { TabPane } = Tabs;

const regions = [
  { name: "Lori", value: "lori" },
  { name: "Shirak", value: "shirak" },
  { name: "Tavush", value: "tavush" },
  { name: "Aragatsotn", value: "aragatsotn" },
  { name: "Kotayk", value: "kotayk" },
  { name: "Armavir", value: "armavir" },
  { name: "Yerevan", value: "yerevan" },
  { name: "Ararat", value: "ararat" },
  { name: "Gegharkunik", value: "gegharkunik" },
  { name: "Vayots Dzor", value: "vayotsDzor" },
  { name: "Syunik", value: "syunik" },
];

const GeoZones = (props) => {
  const [geoZoneProps, setGeoZoneProps] = useState(props);
  const [geoZones, setGeoZones] = useState([]);
  const [modal, setModal] = useState({
    ModalText: "Confirm geo zone delete",
    visible: false,
    confirmLoading: false,
  });
  const [spinning, setSpinning] = useState(true);

  useEffect(() => {
    setGeoZoneProps(props);
  }, [props]);

  const fetchGeoZones = async () => {
    const { data } = await api.geoZones().get();
    let groupedGeoZones = data.reduce((r, a) => {
      r[a.region] = [...(r[a.region] || []), a];
      return r;
    }, {});
    setGeoZones(groupedGeoZones);
    setSpinning(false);
  };

  useEffect(() => {
    fetchGeoZones();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    geoZoneProps.form.validateFields(async (err, values) => {
      if (err) {
        return;
      }
      if ((await AreaExists(values.area, values.id)) > 0) {
        await message.error(`"${values.area}" already exists.`, 2);
        return;
      }
      //post geoZones data
      await api
        .geoZones()
        .post({
          id: values.id,
          region: values.region ?? "",
          area: values.area ?? "",
          description: values.description ?? "",
          amount: values.amount ?? 0,
          translate: {
            area: values.translatedArea ?? "",
            description: values.translatedDescription ?? ""
          }
        })
        .then(async (response) => {
          await fetchGeoZones();
          geoZoneProps.form.resetFields();
          await message.success("Geo Zones updated", 1);
        });
    });
  };

  const onEdit = (geoZone) => {
    geoZoneProps.form.setFieldsValue({
      id: geoZone.id,
      region: geoZone.region,
      area: geoZone.area,
      amount: geoZone.amount,
      description: geoZone.description,
      translatedArea: geoZone.translate.area,
      translatedDescription: geoZone.translate.description
    });
  };

  const showModal = (id) => {
    setModal((modal) => ({
      ...modal,
      visible: true,
      id: id,
    }));
  };

  const handleModalOk = async () => {
    setModal((modal) => ({
      ...modal,
      ModalText: "Please wait...",
      confirmLoading: true,
    }));

    setTimeout(() => {
      setModal((modal) => ({
        ...modal,
        visible: false,
        confirmLoading: false,
      }));
    }, 2000);
    await api.geoZones(modal.id).delete();
    await fetchGeoZones();
    await message.warning("This geo zone successfully deleted", 1);
  };

  const handleModalCancel = () => {
    setModal((modal) => ({
      ...modal,
      visible: false,
      id: -1,
    }));
  };

  const { getFieldDecorator } = geoZoneProps.form;
  return (
    <Row gutter={32}>
      <Col span={6}>
        <Form>
          <Form.Item label="Region">
            {getFieldDecorator("region", {
              rules: [{ required: true, message: "Please select region!" }],
            })(
              <Select placeholder="Select a region" name="region">
                {regions.map((region) => (
                  <Option key={region.value} value={region.value}>
                    {region.name}
                  </Option>
                ))}
              </Select>
            )}
          </Form.Item>
          <Tabs defaultActiveKey="1">
            {languages.map((language) => {
              return (
                <TabPane tab={language.name} key={language.id}>
                  <Form.Item label="Area">
                    {getFieldDecorator(language.id === 1 ? "area" : "translatedArea", {
                      rules: [
                        { required: language.id === 1 ? true : false, message: "Please input area!" },
                      ],
                    })(<Input />)}
                  </Form.Item>
                  <Form.Item label="Description">
                    {getFieldDecorator(language.id === 1 ? "description" : "translatedDescription", {
                      rules: [{ required: false }],
                    })(<Input.TextArea />)}
                  </Form.Item>
                </TabPane>
              );
            })}
          </Tabs>
          <Form.Item label="Amount">
            {getFieldDecorator("amount", {
              rules: [{ required: true, message: "Please set amount!" }],
            })(<InputNumber min={0} />)}
          </Form.Item>
          <Form.Item label="Id" style={{ display: "none" }}>
            {getFieldDecorator("id", {
              rules: [{ required: false }],
            })(<Input />)}
          </Form.Item>
          <Form.Item>
            <Button onClick={handleSubmit} type="primary">
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Col>
      <Col span={18}>
        <Spin spinning={spinning}>
          {Object.entries(geoZones).map((grp, index) => (
            <Collapse key={index} accordion>
              <Panel
                header={regions.find((x) => x.value === grp[0]).name}
                key={grp[0]}
                style={{ marginBottom: 6 }}
              >
                {grp[1].map((item) => (
                  <List.Item
                    key={item.area}
                    actions={[
                      <Button onClick={() => onEdit(item)}>edit</Button>,
                      <Button type="danger" onClick={() => showModal(item.id)}>
                        Delete
                      </Button>,
                      <Modal
                        title="Are you sure?"
                        visible={modal.visible}
                        onOk={handleModalOk}
                        confirmLoading={modal.confirmLoading}
                        onCancel={handleModalCancel}
                      >
                        <p>{modal.ModalText}</p>
                      </Modal>,
                    ]}
                  >
                    <List.Item.Meta
                      title={item.area}
                      description={item.description}
                    />
                    <div>
                      Delivery fee: <strong>{item.amount}</strong>
                    </div>
                  </List.Item>
                ))}
              </Panel>
            </Collapse>
          ))}
        </Spin>
      </Col>
    </Row>
  );
};

const WrappedGeoZoneForm = Form.create({ name: "normal_geozone" })(GeoZones);

export default WrappedGeoZoneForm;
