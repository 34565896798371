import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Row,
  Divider,
  Layout,
  Spin,
  Table,
  Input,
  Col,
  DatePicker,
  Select,
  message,
  List
} from "antd";
import Cookie from "js-cookie";
import Moment from "react-moment";

const jwtToken = Cookie.get("jwtToken") ? Cookie.get("jwtToken") : null;
const API_URL = process.env.REACT_APP_API_URL;
const { Content } = Layout;
const { Search } = Input;
const { RangePicker } = DatePicker;
const { Option } = Select;

const Orders = () => {
  const [orders, setOrders] = useState([]);
  const [spinning, setSpinning] = useState(true);

  const handleOnSearch = async value => {
    await axios
      .get(`${API_URL}/orders/`, {
        headers: {
          Authorization: `Bearer ${jwtToken}`
        }
      })
      .then(response => {
        let tempOrders = [];
        response.data.forEach(element => {
          tempOrders.push({
            id: element.id,
            key: element.id,
            date: element.date ? (
              <Moment format="DD/MM/YYYY HH:mm">{element.date}</Moment>
            ) : (
              ""
            ),
            name: element.customer ? element.customer.name : "",
            lastName: element.customer ? element.customer.lastName : "",
            region: element.customer ? element.customer.region : "",
            area: element.customer ? element.customer.area : "",
            mail: element.customer ? element.customer.mail : "",
            address: element.customer ? element.customer.address : "",
            phone: element.customer ? element.customer.phone : "",
            secondPhone: element.customer ? element.customer.secondPhone : "",
            paymentStatus: element.paymentStatus,
            fulfillmentStatus: element.fulfillmentStatus,
            totalAmount: element.totalAmount,
            products: element.products
          });
        });
        if (!value) {
          setOrders(tempOrders);
        } else {
          let searched = tempOrders.filter(x =>
            x.name.toUpperCase().includes(value.toUpperCase())
          );
          setOrders(searched);
        }
      });
  };

  const columns = [
    {
      title: "id",
      dataIndex: "id",
      key: "id",
      sorter: (a, b) => a.id - b.id
    },
    {
      title: "date",
      dataIndex: "date",
      key: "date"
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      sorter: (a, b) => a.name.length - b.name.length
    },
    {
      title: "Last Name",
      dataIndex: "lastName",
      key: "lastName",
      sorter: (a, b) => a.lastName.length - b.lastName.length
    },
    {
      title: "Region",
      key: "region",
      dataIndex: "region"
    },
    {
      title: "Area",
      key: "area",
      dataIndex: "area"
    },
    {
      title: "Address",
      key: "address",
      dataIndex: "address"
    },
    {
      title: "eMail",
      key: "mail",
      dataIndex: "mail"
    },
    {
      title: "Phone",
      key: "phone",
      dataIndex: "phone"
    },
    {
      title: "Phone2",
      key: "secondPhone",
      dataIndex: "secondPhone"
    },
    {
      title: "Total Amount",
      key: "totalAmount",
      dataIndex: "totalAmount"
    },
    {
      title: "Payment Status",
      key: "payment",
      render: (text, record) => (
        <Select
          defaultValue={record.paymentStatus}
          style={{ width: 120 }}
          onChange={e => handlePaymentStatus(e, record.id)}
        >
          <Option value="pending">Pending</Option>
          <Option value="payed">Payed</Option>
        </Select>
      )
    },
    {
      title: "Fulfillment Status",
      key: "fulfillment",
      render: (text, record) => (
        <Select
          defaultValue={record.fulfillmentStatus}
          style={{ width: 120 }}
          onChange={e => handleFulfillmentStatus(e, record.id)}
        >
          <Option value="pending">Pending</Option>
          <Option value="shipped">Shipped</Option>
        </Select>
      )
    }
  ];

  const handlePaymentStatus = async (event, id) => {
    await axios
      .post(
        `${API_URL}/orders/`,
        {
          id: parseInt(id),
          paymentStatus: event
        },
        {
          headers: {
            Authorization: `Bearer ${jwtToken}`
          }
        }
      )
      .then(response => message.success("Order's payment status updated", 1));
  };

  const handleFulfillmentStatus = async (event, id) => {
    await axios
      .post(
        `${API_URL}/orders/`,
        {
          id: parseInt(id),
          fulfillmentStatus: event
        },
        {
          headers: {
            Authorization: `Bearer ${jwtToken}`
          }
        }
      )
      .then(response =>
        message.success("Order's fulfillment status updated", 1)
      );
  };

  useEffect(() => {
    const getOrders = async () => {
      await axios
        .get(`${API_URL}/orders/`, {
          headers: {
            Authorization: `Bearer ${jwtToken}`
          }
        })
        .then(response => {
          let tempOrders = [];
          response.data.forEach(element => {
            tempOrders.push({
              id: element.id,
              key: element.id,
              date: element.date ? (
                <Moment format="DD/MM/YYYY HH:mm">{element.date}</Moment>
              ) : (
                ""
              ),
              name: element.customer ? element.customer.name : "",
              lastName: element.customer ? element.customer.lastName : "",
              region: element.customer ? element.customer.region : "",
              area: element.customer ? element.customer.area : "",
              mail: element.customer ? element.customer.mail : "",
              address: element.customer ? element.customer.address : "",
              phone: element.customer ? element.customer.phone : "",
              secondPhone: element.customer ? element.customer.secondPhone : "",
              paymentStatus: element.paymentStatus,
              fulfillmentStatus: element.fulfillmentStatus,
              totalAmount: element.totalAmount,
              products: element.products
            });
          });
          setOrders(tempOrders);
          setSpinning(false);
        });
    };
    getOrders();
  }, []);

  const onDateRangeChange = async (date, dateString) => {
    await axios
      .get(`${API_URL}/orders/`, {
        headers: {
          Authorization: `Bearer ${jwtToken}`
        }
      })
      .then(response => {
        let tempOrders = [];
        response.data.forEach(element => {
          tempOrders.push({
            id: element.id,
            key: element.id,
            date: element.date ? (
              <Moment format="YYYY-MM-DD HH:mm">{element.date}</Moment>
            ) : (
              ""
            ),
            name: element.customer ? element.customer.name : "",
            lastName: element.customer ? element.customer.lastName : "",
            region: element.customer ? element.customer.region : "",
            area: element.customer ? element.customer.area : "",
            mail: element.customer ? element.customer.mail : "",
            address: element.customer ? element.customer.address : "",
            phone: element.customer ? element.customer.phone : "",
            secondPhone: element.customer ? element.customer.secondPhone : "",
            paymentStatus: element.paymentStatus,
            fulfillmentStatus: element.fulfillmentStatus,
            totalAmount: element.totalAmount,
            products: element.products
          });
        });
        if (dateString[0] === "") {
          setOrders(tempOrders);
        } else {
          let dateOne = Date.parse(dateString[0]);
          let dateTwo =
            dateString[0] !== dateString[1]
              ? Date.parse(dateString[1])
              : Date.parse(dateString[1]) + 86400000;
          let searched = tempOrders.filter(
            x =>
              Date.parse(x.date.props.children) > dateOne &&
              Date.parse(x.date.props.children) < dateTwo
          );
          setOrders(searched);
        }
      });
  };

  return (
    <div className="orders">
      <Spin spinning={spinning} delay={300}>
        <Layout>
          <Content style={{ padding: "32px 50px" }}>
            <h1>Orders</h1>
            <Row type="flex" justify="center" align="middle" gutter={32}>
              <Col span={12}>
                <Search
                  type="text"
                  name="search"
                  enterButton="Search"
                  placeholder="input name"
                  onSearch={handleOnSearch}
                />
              </Col>
              <Col span={12}>
                <RangePicker onChange={onDateRangeChange} />
              </Col>
            </Row>
            <Divider />
            <Row type="flex" justify="center" align="middle">
              <Table
                size="middle"
                columns={columns}
                expandedRowRender={record => (
                  <List
                    size="small"
                    itemLayout="horizontal"
                    dataSource={record.products}
                    renderItem={item => (
                      <List.Item>
                        <List.Item.Meta
                          title={item.name}
                          description={item.code}
                        />
                        <div>price: {item.price}</div>
                        <Divider type="vertical"/>
                        <div>quantity: {item.quantity}</div>
                      </List.Item>
                    )}
                  />
                )}
                dataSource={orders}
              />
            </Row>
            <Divider />
          </Content>
        </Layout>
      </Spin>
    </div>
  );
};
export default Orders;
