import React from "react";
import CKEditor from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-editor-classic/src/classiceditor";
import Essentials from "@ckeditor/ckeditor5-essentials/src/essentials";
import Paragraph from "@ckeditor/ckeditor5-paragraph/src/paragraph";
import Bold from "@ckeditor/ckeditor5-basic-styles/src/bold";
import Italic from "@ckeditor/ckeditor5-basic-styles/src/italic";
import Heading from "@ckeditor/ckeditor5-heading/src/heading";
import SimpleUploadAdapter from "@ckeditor/ckeditor5-upload/src/adapters/simpleuploadadapter";
import UploadAdapter from "@ckeditor/ckeditor5-adapter-ckfinder/src/uploadadapter";
import Autoformat from "@ckeditor/ckeditor5-autoformat/src/autoformat";
import Link from "@ckeditor/ckeditor5-link/src/link";
import List from "@ckeditor/ckeditor5-list/src/list";
import Alignment from "@ckeditor/ckeditor5-alignment/src/alignment";

const Editor = ({
  description,
  setDescription,
  languageId,
  translatedDescription,
  setTranslatedDescription,
}) => {
  const onDescriptionChange = (event, editor) => {
    const data = editor.getData();
    if (languageId) {
      setDescription(data);
    }
  };
  const onTranslateDescriptionChange = (event, editor) => {
    const data = editor.getData();
    if (languageId) {
      setTranslatedDescription(data);
    }
  };

  const editorConfiguration = {
    plugins: [
      Essentials,
      UploadAdapter,
      Autoformat,
      Paragraph,
      Bold,
      Italic,
      Heading,
      Link,
      List,
      SimpleUploadAdapter,
      Alignment,
    ],
    toolbar: [
      "heading",
      "|",
      "alignment",
      "bold",
      "italic",
      "link",
      "bulletedList",
      "numberedList",
      "undo",
      "redo",
    ],
  };

  return (
    <div>
      <CKEditor
        editor={ClassicEditor}
        onInit={(editor) => console.log("Editor is ready to use!", editor)}
        onChange={
          languageId === 1 ? onDescriptionChange : onTranslateDescriptionChange
        }
        config={editorConfiguration}
        data={languageId === 1 ? description : translatedDescription}
      />
    </div>
  );
};

export default Editor;
