import React, { useState, useEffect } from "react";
import {
  Row,
  Input,
  Button,
  Form,
  Layout,
  Col,
  message,
  Spin,
  Icon,
  Upload,
  Modal,
  Tabs,
  InputNumber,
  Divider,
} from "antd";
import languages from "../Languages/languages.json";
import api from "../../Api";
import WrappedGeoZoneForm from "./GeoZones";

const API_URL = process.env.REACT_APP_API_URL;
const { Content } = Layout;
const { TabPane } = Tabs;
const { TextArea } = Input;

//show image
const getBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
};

const Settings = () => {
  const [settings, setSettings] = useState({});
  const [translate, setTranslate] = useState(
    settings ? (settings.translate ? settings.translate : {}) : {}
  );
  const [spinning, setSpinning] = useState(true);
  const [images, setImages] = useState([]);
  const [oldImages, setOldImages] = useState([]);

  useEffect(() => {
    const fetchSettings = async () => {
      const imagesUrls = [];
      const { data } = await api.informations().get();
      if (data[0].logoUrl !== "") {
        imagesUrls.push({
          uid: data[0] ? data[0].id : -1,
          name: "logo",
          status: "done",
          url: `${API_URL}/${data[0] ? data[0].logoUrl : ""}`,
        });
        setImages((images) => ({ ...images, fileList: imagesUrls }));
        setOldImages(imagesUrls);
      }
      setSettings(data[0]);
      setTranslate(data[0] ? data[0].translate : "");
      setSpinning(false);
    };
    fetchSettings();
  }, []);

  const handleSettingChange = (event) => {
    event.persist();
    setSettings((settings) => ({
      ...settings,
      [event.target.name]: event.target.value,
    }));
  };

  const onExchangeRateChange = (value) => {
    setSettings((settings) => ({
      ...settings,
      exchangeRate: value,
    }));
  };

  const handleSettingTranslateChange = (event) => {
    event.persist();
    setTranslate((translate) => ({
      ...translate,
      code: "hy",
      [event.target.name]: event.target.value,
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    let formData = new FormData();
    if (!!images.fileList) {
      for (let i = 0; i < images.fileList.length; i++) {
        formData.append("image", images.fileList[i].originFileObj);
      }
      await api
        .uploads()
        .post(formData)
        .then(async (response) => {
          response.data.forEach((res) => {
            api.informations().post({ id: 1, logoUrl: res.path });
          });
        });
    }
    // get images differences
    let intersection = oldImages.filter((x) => !images.fileList.includes(x));
    for (let i = 0; i < intersection.length; i++) {
      let uid = intersection[i].uid;
      await api.images(uid).delete();
    }
    //post settings data
    await api
      .informations()
      .post({
        id: 1,
        companyName: settings.companyName ?? "",
        description: settings.description ?? "",
        address1: settings.address1 ?? "",
        address2: settings.address2 ?? "",
        mail: settings.mail ?? "",
        geoLocation: settings.geoLocation ?? "",
        phone1: settings.phone1 ?? "",
        phone2: settings.phone2 ?? "",
        translate: translate,
        logoUrl: images.fileList ? images.fileList.length === 0 ? "" : images.fileList.url : "",
        exchangeRate: settings.exchangeRate,
        helmet: settings.helmet
      })
      .then(async (response) => {
        await message.success("Settings updated", 1);
        window.location.reload();
      });
  };

  const handleCancel = () => {
    setImages((images) => ({ ...images, previewVisible: false }));
  };

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setImages((images) => ({
      ...images,
      previewImage: file.url || file.preview,
      previewVisible: true,
    }));
  };

  const handleImageChange = ({ fileList }) => {
    if (fileList.length > 0) {
      fileList[0].bannerName = "bottomBanner";
    }
    setImages((images) => ({ ...images, fileList }));
  };

  const uploadButton = (
    <div>
      <Icon type="plus" />
      <div className="ant-upload-text">Upload</div>
    </div>
  );
  
  return (
    <div className="settings">
      <Form onSubmit={handleSubmit}>
        <Tabs defaultActiveKey="1">
          <TabPane tab="Information settings" key="1">
            <Spin spinning={spinning}>
              <Layout>
                <Content style={{ padding: "32px 50px" }}>
                  <Row>
                    <Col span={12}>
                      <div>
                        <h2>Settings</h2>
                      </div>
                    </Col>
                    <Col span={12}></Col>
                  </Row>
                  <Row align="top" gutter={32}>
                    <Col span={6}>
                      <Form.Item>
                        <div className="clearfix">
                          <Upload
                            action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                            accept=".jpg, .jpeg, .png, .gif"
                            listType="picture-card"
                            fileList={images.fileList}
                            onPreview={handlePreview}
                            onChange={handleImageChange}
                            name="bottomBanner"
                          >
                            {!!images.fileList && images.fileList.length >= 1
                              ? null
                              : uploadButton}
                          </Upload>
                          <Modal
                            visible={images.previewVisible}
                            footer={null}
                            onCancel={handleCancel}
                          >
                            <img
                              alt="example"
                              style={{ width: "100%" }}
                              src={images.previewImage}
                            />
                          </Modal>
                        </div>
                      </Form.Item>
                    </Col>
                    <Col span={18}>
                      <Tabs defaultActiveKey="1">
                        {languages.map((language) => {
                          return (
                            <TabPane tab={language.name} key={language.id}>
                              <Form.Item label="Company Name">
                                <Input
                                  type="text"
                                  name="companyName"
                                  onChange={
                                    language.id === 1
                                      ? handleSettingChange
                                      : handleSettingTranslateChange
                                  }
                                  value={
                                    language.id === 1
                                      ? settings
                                        ? settings.companyName
                                        : ""
                                      : translate
                                      ? translate.companyName
                                      : ""
                                  }
                                />
                              </Form.Item>
                              <Form.Item label="Description">
                                <TextArea
                                  type="text"
                                  name="description"
                                  rows={4}
                                  onChange={
                                    language.id === 1
                                      ? handleSettingChange
                                      : handleSettingTranslateChange
                                  }
                                  value={
                                    language.id === 1
                                      ? settings
                                        ? settings.description
                                        : ""
                                      : translate
                                      ? translate.description
                                      : ""
                                  }
                                />
                              </Form.Item>
                              <Form.Item label="Address 1">
                                <Input
                                  type="text"
                                  name="address1"
                                  onChange={
                                    language.id === 1
                                      ? handleSettingChange
                                      : handleSettingTranslateChange
                                  }
                                  value={
                                    language.id === 1
                                      ? settings
                                        ? settings.address1
                                        : ""
                                      : translate
                                      ? translate.address1
                                      : ""
                                  }
                                />
                              </Form.Item>
                              <Form.Item label="Address 2">
                                <Input
                                  type="text"
                                  name="address2"
                                  onChange={
                                    language.id === 1
                                      ? handleSettingChange
                                      : handleSettingTranslateChange
                                  }
                                  value={
                                    language.id === 1
                                      ? settings
                                        ? settings.address2
                                        : ""
                                      : translate
                                      ? translate.address2
                                      : ""
                                  }
                                />
                              </Form.Item>
                              <Form.Item label="Mail">
                                <Input
                                  type="text"
                                  name="mail"
                                  onChange={
                                    language.id === 1
                                      ? handleSettingChange
                                      : handleSettingTranslateChange
                                  }
                                  value={
                                    language.id === 1
                                      ? settings
                                        ? settings.mail
                                        : ""
                                      : translate
                                      ? translate.mail
                                      : ""
                                  }
                                />
                              </Form.Item>
                              <Form.Item label="Geo Location">
                                <Input
                                  type="text"
                                  name="geoLocation"
                                  onChange={
                                    language.id === 1
                                      ? handleSettingChange
                                      : handleSettingTranslateChange
                                  }
                                  value={
                                    language.id === 1
                                      ? settings
                                        ? settings.geoLocation
                                        : ""
                                      : translate
                                      ? translate.geoLocation
                                      : ""
                                  }
                                />
                              </Form.Item>
                              <Form.Item label="Phone 1">
                                <Input
                                  type="text"
                                  name="phone1"
                                  onChange={
                                    language.id === 1
                                      ? handleSettingChange
                                      : handleSettingTranslateChange
                                  }
                                  value={
                                    language.id === 1
                                      ? settings
                                        ? settings.phone1
                                        : ""
                                      : translate
                                      ? translate.phone1
                                      : ""
                                  }
                                />
                              </Form.Item>
                              <Form.Item label="Phone 2">
                                <Input
                                  type="text"
                                  name="phone2"
                                  onChange={
                                    language.id === 1
                                      ? handleSettingChange
                                      : handleSettingTranslateChange
                                  }
                                  value={
                                    language.id === 1
                                      ? settings
                                        ? settings.phone2
                                        : ""
                                      : translate
                                      ? translate.phone2
                                      : ""
                                  }
                                />
                              </Form.Item>
                            </TabPane>
                          );
                        })}
                      </Tabs>
                    </Col>
                  </Row>
                </Content>
              </Layout>
            </Spin>
          </TabPane>
          <TabPane tab="Additional" key="2">
            <Layout>
              <Content style={{ padding: "32px 50px" }}>
                <Row>
                  <Col span={12}>
                    <div>
                      <h2>Additional</h2>
                    </div>
                  </Col>
                  <Col span={12}></Col>
                </Row>
                <Form.Item label="Exchange rate">
                  <InputNumber min={0} value={settings.exchangeRate} onChange={onExchangeRateChange} />
                </Form.Item>
              </Content>
            </Layout>
          </TabPane>
          <TabPane tab="Geo Zones" key="3">
            <Layout>
              <Content style={{ padding: "32px 50px" }}>
                <Row>
                  <Col span={12}>
                    <div>
                      <h2>Geo Zones</h2>
                    </div>
                  </Col>
                  <Col span={12}></Col>
                </Row>
                <WrappedGeoZoneForm />
              </Content>
            </Layout>
          </TabPane>
          <TabPane tab="Header code" key="4">
          <Layout>
              <Content style={{ padding: "32px 50px" }}>
            <Form.Item label="Paste your code here" style={{ width: "100%" }}>
              <TextArea 
              name="helmet" 
              type="text"                                   
              rows={40}
              onChange={handleSettingChange}
              value={settings && settings.helmet ? settings.helmet : ""} 
              />
            </Form.Item>
            </Content>
            </Layout>
          </TabPane>
        </Tabs>
        <Row type="flex" justify="center" align="middle">
        <Divider />
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Update
          </Button>
        </Form.Item>
        </Row>
      </Form>
    </div>
  );
};

export default Settings;
